/* App.css */

body {
  background-color: #222222;
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #222222;
}

.header-right {
    flex-direction: column;
}

.header-left, .header-center, .header-right {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 2px;
}

.header-center {
  justify-content: start;
}

.header-right {
  align-items: end;
  justify-content: center;
  flex-wrap: wrap;
}

.header-info {
  display: flex;
  flex: 0 1 auto;
  margin-bottom: 2px;
  flex-flow: row wrap;
  align-items: start;
  justify-content: center;
}

.header-buttons {
  display: flex;
  flex: 0 1 auto;
  margin-bottom: 2px;
  flex-flow: row wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.material-search-field,
.search-field,
.clear-button,
.pathfinding-toggle-container {
  margin: 5px;
}

.toggle-stack-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  position: relative;
}

.App-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.App-header h1 {
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
}

.filter-toggle {
  background-color: #f7a600;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}

.filter-categories {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.filter-category {
  margin: 0 10px;
}

.filter-category h4 {
  margin: 0 0 5px 0;
  color: #f7a600;
  font-size: 14px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.checkbox-label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  color: #fff;
  font-size: 12px;
}

.checkbox-label input {
  margin-right: 5px;
}

.material-search-field input,
.search-field input {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  background-color: #333;
  color: #fff;
  margin-left: 5px;
}

.search-controls button {
  padding: 5px 10px;
  background-color: #f7a600;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 5px;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

#map-container {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.sidebar {
  width: max(20%, 250px);
  background-color: #333333;
  transition: width 0.3s ease;
  position: relative;
  z-index: 10;
}

.sidebar.collapsed {
  width: 20px;
}

.toggle-btn {
  position: absolute;
  top: 10px;
  left: -30px;
  background-color: #444444;
  border: none;
  color: white;
  padding: 5px;
  cursor: pointer;
}

.sidebar-content {
  padding: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
}

.sidebar-content svg {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.planet-info-sb {
  margin-bottom: 20px;
}

.resource-item-sb {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.concentration-bar-container-sb {
  width: 100px;
  background-color: #ddd;
  height: 10px;
  margin-left: 5px;
}

.concentration-bar-sb {
  height: 100%;
  transition: width 0.3s ease;
}

.resource-percentage {
  display: inline-block;
  min-width: 50px;
  text-align: right;
  margin-left: 5px;
}

.pathfinding-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.pathfinding-toggle .toggle-token {
  margin: 0px;
  margin-right: 8px;
}

.pathfinding-toggle .toggle-token::before {
  content: "Pathfinding";
}

.meteor-density-toggle .toggle-token::before {
  content: "Data Overlay";
}

.info-tooltip-container {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.tooltip {
  font-size: 12px;
  line-height: 1.4;
}

.tooltip h4 {
  font-size: 14px;
  margin-bottom: 8px;
}

.tooltip ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tooltip li {
  margin-bottom: 4px;
}

.search-highlight {
  transform-box: fill-box;
  transform-origin: center;
}

search-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.search-button {
  background-color: #f7a600;
  border: none;
  color: white;
  padding: 6px 5px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.search-notification {
  position: fixed;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.clear-button {
  background-color: #6c757d;
  border: none;
  color: white;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

.checkbox-label:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  bottom: 0;
  left: -15px;
  transform: translateX(-50%);
}

.planet-condition-icon {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  cursor: help;
}

.planet-condition-icon .tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
}

.company-base-indicator {
  background-color: #f7a600;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  margin-top: 5px;
  display: inline-block;
}

.planet-info-sb.highlighted {
  border-left: 3px solid #f7a600;
  padding-left: 5px;
}

.company-search-toggle {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Adjusted css for small screen sizes */

@media (max-width: 820px) {
  .App-header {
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }

  .header-left {
    display: none; /* This hides the logo and title */
  }

  .header-right, .header-info .header-buttons {
    flex-direction: column;
  }

  .header-left, .header-center, .header-right, .header-info .header-buttons {
    flex: 0 1 auto;
  }

  .filter-toggle {
    padding: 3px 6px;
    font-size: 12px;
  }

  .filter-categories {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .filter-category {
    margin: 3px;
  }

  .filter-category h4 {
    font-size: 11px;
  }

  .checkbox-label {
    font-size: 10px;
  }

  .material-search-field input,
  .search-field input {
    padding: 3px 5px;
    font-size: 12px;
  }

  .search-button {
    padding: 4px 5px;
    font-size: 12px;
  }

  .clear-button {
    padding: 4px 5px;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .info-tooltip-container {
    margin-top: 0px;
    margin-left: 5px;
  }

  .pathfinding-toggle .toggle-token::before {
    content: "Path";
  }

  .meteor-density-toggle .toggle-token::before {
    content: "Data";
  }

  .sidebar {
    width: max(20%, 250px);
  }

  .sidebar.collapsed {
    width: 10px;
  }

  .sidebar-content {
    padding: 10px;
    padding-bottom: 5px;
  }

  .sidebar-content h3 {
    font-size: 16px;
  }

  .sidebar-content h2 {
    font-size: 22px;
  }

  .sidebar-content svg {
    width: 18px;
    height: 18px;
  }

  .resource-item-sb {
    font-size: 14px;
  }
}